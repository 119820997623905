<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Info Klinik'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/images/default-profile.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col-md">
                <table class="table mb-3">
                  <tr>
                    <td><strong>Nama Klinik</strong></td>
                    <td>{{ regis.clinic_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alamat</strong></td>
                    <td>{{ regis.city_name +", "+ regis.province_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Terdaftar Sejak</strong></td>
                    <td>{{ regis.register_date_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Paket Langganan Aktif</strong></td>
                    <td>{{ regis.subscribe_package_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Berlangganan Aktif Sampai</strong></td>
                    <td>{{ data.display_end_date+" ("+data.diff_date +" Hari Lagi)" }}</td>
                  </tr>
                  <tr>
                    <td><strong>Satus Berlangganan</strong></td>
                    <td>
                      <b-badge
                        pill
                        variant="success"
                        v-if="data.is_subscribe == 1"
                        >Berlangganan Aktif</b-badge
                      >
                      <b-badge
                        pill
                        variant="warning"
                        v-if="data.is_subscribe == 0"
                        >Masa Trial</b-badge
                      >
                    </td>
                  </tr>
                 
                </table>
                <!-- <button
                  @click="
                    $router.push({
                      name: 'donor-edit',
                      params: { id: data.id },
                    })
                  "
                  class="btn mx-1 btn-success"
                  v-if="btn"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button> -->
                <!-- <button
                  class="btn mx-1 btn-danger"
                  v-if="btn"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button> -->
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import saasModule from "@/core/modules/CrudSaasModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Data
      data: [],
      regis:[],
      // Other
      imgLoaded: false,
      // User Access
      btn: false,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    // async deleteData() {
    //   let result = await saasModule.delete('billings/' + this.$route.params.id)
    //   // If Deleted
    //   if (result) {
    //     // Redirect To List
    //     this.$router.push('/donors')
    //   }
    // },

    async get() {
      this.data = JSON.parse(localStorage.getItem('tenantconfig'))
    },

    async getRgis() {
      this.regis = await saasModule.get("registrants/" + this.data.registrant_id);
     
    },

    // setActiveButton(){
    //   let access_right_user = window.localStorage.getItem("access_right_display")
    //   let access_right = JSON.parse(access_right_user)

    //   let a
    //   for(a = 0; a < access_right.length; a++){
    //     if(access_right[a] == "2202"){
    //       this.btn = true
    //     }
    //   }
    // },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Info Klinik" },
    ]);

    this.get();
    this.getRgis();
    // this.setActiveButton();
  },
};
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>